import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconClose } from '@medifind/icons';
import { IconMarkerHollow } from '@medifind/icons';
import { getAddressDetailsFromPlaceId, locationAutocomplete } from '@medifind/interface';
import { Autocomplete } from '@medifind/shared-basic-components';
import { Button } from '@medifind/shared-basic-components';
import { clearDoctorSearchRadius, setBrowserLocation, setCustomLocation, useLocationStore } from '@medifind/zustand';
import { Filter } from './Filter';
import styles from './FilterShared.module.scss';

const LocationFilter = ({
  onLocationSelect,
  classes = {},
  what,
  selectValue,
  style = {},
  label = 'Enter a location',
  smallAt,
  noHeader = false,
  showWrapper = true,
  showGetLocationButton,
  showClearButton,
  placeholder = 'Enter a location',
  countryCodesFilter,
  onError,
  disableErrorMessage = false,
  onLocationClear,
}) => {
  const location = useLocationStore();
  const { lat, lon } = location;
  const locationChangeRef = useRef();
  const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState();
  const setManualChange = () => {
    locationChangeRef.current = true;
  };
  useEffect(() => {
    onLocationSelect && onLocationSelect(location, locationChangeRef.current);
    if (locationChangeRef.current) {
      // Note: Only need to clear dynamic radiuses here
      // clearTrialRadiusFilter()
      // clearGenericRadiusFilter()
      clearDoctorSearchRadius();
    }
    locationChangeRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setErrorIfNothingSelected = ({ inputValue }) => {
    setError(
      (!location.locationString || (locationChangeRef.current && location.locationString !== inputValue)) && inputValue
        ? 'Select a location'
        : '',
    );
  };
  useEffect(() => onError && onError(error), [error]);

  const handleLocationInputChange = (input) => {
    if (input.trim()) {
      locationAutocomplete({ input, countryCodes: countryCodesFilter, lat, lon })
        .then(({ predictions, status }) => {
          setPredictions(predictions);
        })
        .catch(() => {});
    }
  };

  const handleLocationSelect = ({ value }) => {
    setManualChange();
    if (value)
      getAddressDetailsFromPlaceId({ placeId: value })
        .then(({ result }) => {
          setCustomLocation(result);
          // clear the error when location selected
          onError && onError('');
        })
        .catch(() => {});
  };

  const handleLocationInputIconClick = () => {
    setManualChange();
    // If there is a countryCodesFilter that means we cannot show a global radius, and it defaults to the first item in countryCodesFilter list
    if (countryCodesFilter?.length > 0) {
      setCustomLocation({ countryCode: countryCodesFilter[0], fidelity: 5 });
    } else {
      setCustomLocation({});
    }
  };

  const handleLocationIconButtonClick = (event) => {
    setManualChange();
    setBrowserLocation().catch((err) => {
      setError(err?.message);
    });
  };

  const clearSelectedLocation = () => {
    setManualChange();
    setCustomLocation({});
    onLocationClear && onLocationClear();
  };

  return (
    <Filter
      classes={classes}
      label={label}
      what={what}
      noHeader={noHeader}
      showWrapper={showWrapper}
      style={style}
      headerChildren={
        <Button
          noPadding
          color="transparent-blue"
          focus="outline"
          classes={{ container: styles['action'], icon: classes.locationIcon, input: classes.locationIcon }}
          style={style.useCurrentButton}
          label={'Use current'}
          onClick={(e) => {
            handleLocationIconButtonClick();
            e.preventDefault();
          }}
          icon={<IconMarkerHollow color={'blue'} style={style.useCurrentIcon} />}
          screenReaderText="Use Current Location"
        />
      }
      smallAt={smallAt}
      hideReset
      placeholder={location.locationString || 'Enter a location'}
    >
      <Autocomplete
        labelId={'-'} // Is replaced by <Filter>
        hideLabel={noHeader}
        id="ac-location-filter"
        label="Location"
        placeholder={placeholder}
        activeFirstOption={true}
        items={predictions}
        showWrapper={showWrapper}
        onChange={handleLocationInputChange}
        onSelect={handleLocationSelect}
        onClear={clearSelectedLocation}
        onBlur={(args) => {
          setErrorIfNothingSelected(args);
        }}
        onFocus={() => setError('')}
        inputValue={location.locationString}
        inputProp={location}
        clearSelectOnTyping={true}
        classes={{
          ...classes,
          menuWrapper: classNames(classes.menuWrapper, styles['filter-menu-wrapper']),
          inputWrapper: classNames(classes.inputWrapper, styles['filter-input-wrapper']),
        }}
        style={style}
        inputIcon={[
          ...(showClearButton && location.locationString
            ? [
                {
                  icon: <IconClose size="medium" style={style.icon} />,
                  onClick: handleLocationInputIconClick,
                  label: 'Clear Location',
                  clearInput: true,
                },
              ]
            : []),
          ...(showGetLocationButton
            ? [
                {
                  icon: <IconMarkerHollow color={'blue'} style={style.icon} />,
                  onClick: handleLocationIconButtonClick,
                  label: 'Use Current Location',
                  clearInput: false,
                },
              ]
            : []),
        ]}
      />
      {!disableErrorMessage && error && <div className={styles['error-message']}>{error}</div>}
      {/* {countryCodesFilter && !(location?.fidelity <= 5) && <div className={styles['error']}>Please enter a location</div>} */}
    </Filter>
  );
};

LocationFilter.propTypes = {
  onLocationSelect: PropTypes.func,
  onLocationClear: PropTypes.func,
  what: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  selectValue: PropTypes.object,
  smallAt: PropTypes.string,
  placeholder: PropTypes.string,
  noHeader: PropTypes.bool,
  showWrapper: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showGetLocationButton: PropTypes.bool,
  countryCodesFilter: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func,
  disableErrorMessage: PropTypes.bool,
};

export { LocationFilter as default, LocationFilter };
